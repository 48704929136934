<template>
  <!-- Multi Step Page -->
  <div class="create-page" v-if="isReady">
    <div class="create-page__header">
      <CRow class="mr-0 ml-0">
        <CCol col="12" sm="6" class="d-flex align-items-center pl-0">
          <div class="zq-page-title-wrapper d-flex">
            <h3 class="zq-page-title">{{ texts.createPage.title }}</h3>
            <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
          </div>
        </CCol>
        <CCol col="12" sm="6">
          <ActionCreateBtns
            :currentStep="currentStep"
            :totalStep="totalStep"
            :finishAction="createEntity"
            @updateCurrentStep="updateCurrentStep"
            @next-step="nextStep"
          />
        </CCol>
      </CRow>
      <WizardHeader
        :currentStep="currentStep"
        :steps="steps"
        @updateCurrentStep="updateCurrentStep"
      />
    </div>
    <div class="content">
      <FormBuilder
        v-if="currentStep === 0"
        :list="formList"
        @updated="updateSettingsData"
        :page="{ title: texts.createPage.title, info: descriptions }"
        :isCreateHeader="false"
        :isSettingsStep="true"
      />
      <CreateScheduling
        v-if="steps.find(step => step.key === 'scheduling') && currentStep === getStepNumber('scheduling')"
        @updateSchedulingData="updateSchedulingData"
        @resetValidate="resetSchedulingValidate"
        :schedulingData="schedulingData"
        :name="settingsData.name"
        :isFrom="schedulingDataValid_startDate"
        :isScheduleType="schedulingDataValid_scheduleType"
        :isEvery="schedulingDataValid_every"
        :isLimit="schedulingDataValid_scheduleOccurrencesLimit"
        :isMessagingPage="true"
      />
      <CreateTranslations
        v-if="steps.find(step => step.key === 'translations') && currentStep === getStepNumber('translations')"
        :entityData="settingsData"
        :translatableFields="translatableFields"
        :translationsData="translationsData"
        @updated="updateTranslationsData"
        :stepNumber="getStepNumber('translations') + 1"
      />
      <SummaryStep
        v-if="currentStep === getStepNumber('summary')"
        :settingsData="settingsData"
        :schedulingData="schedulingData"
        :translationsData="translationsData"
        :stepNumber="getStepNumber('summary') + 1"
        :model="model"
        :translatableFields="translatableFields"
      />
    </div>
  </div>
  <PreviewSpiner
    v-else
  />
  <!-- /Multi Step Page -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import ActionCreateBtns from '@/shared/components/steps/ActionCreateBtns';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import WizardHeader from '@/shared/components/steps/Header';
import CreateTranslations from '@/shared/components/supportModels/translations/CreateTranslations';
import CreateScheduling from '@/shared/components/supportModels/scheduling/CreateScheduling';
import SummaryStep from '@/shared/components/steps/SummaryStep';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import { translationsTransform } from '@/utils/translationsUtils';
import { stepSubTitles } from '@/config/pageTexts/stepSubTitles.json';
import { notifications } from '@/config/descriptions/notifications.json';
import { notificationsTexts } from '@/config/pageTexts/notifications.json';
import notificationFields from '@/generated/ziqni/store/modules/notifications/fields';
import { dateUTC } from '@/utils/dateUTC';
import { delay } from 'lodash';
import PreviewSpiner from "@/shared/UI/Spiner.vue";

export default {
  name: 'CreateNotification',
  components: {
    PreviewSpiner,
    ActionCreateBtns,
    IconWithTooltip,
    WizardHeader,
    CreateTranslations,
    CreateScheduling,
    SummaryStep,
  },
  data() {
    return {
      model: 'notifications',
      isReady: true,
      currentStep: 0,
      totalStep: 0,
      descriptions: {
        ...notifications.create,
      },
      texts: {
        ...notificationsTexts,
      },
      steps: [],
      stepKeys: [],
      firstStep: {
        title: 'Settings',
        subTitle: stepSubTitles.settings,
        step: 0,
      },
      lastStep: {
        title: 'Summary',
        subTitle: stepSubTitles.summary,
        key: 'summary',
        step: 2,
      },
      formList: [],
      settingsData: {},
      translationsData: {},
      translationsMap: {},
      requiredFields: [],
      translatableFields: [],
      schedulingData: {
        scheduleOccurrencesLimit: null,
        scheduleType: '',
        constraints: [],
        endDate: '',
        startDate: dateUTC(),
        every: [],
      },
      schedulingDataValid_startDate: null,
      schedulingDataValid_every: null,
      schedulingDataValid_scheduleOccurrencesLimit: null,
      schedulingDataValid_scheduleType: null,
      transformedRewards: [],
    };
  },
  computed: {
    ...mapGetters('notifications', ['message', 'loading']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
  },
  provide() {
    return {
      stepKeys: this.stepKeys,
      model: this.model,
    }
  },
  watch: {
    message(val) {
      if (val === this.texts.createPage.duplicateMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      } else if (val === this.texts.createPage.emptyMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('notifications', ['handleCreateNotifications']),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);

      this.formList = fieldHelpers.prepareCreateFormList(
        notificationFields,
        notificationsTexts.createPage,
        notifications.create
      );

      const statusIdx = this.formList.findIndex(f => f.key === 'status');
      if (statusIdx !== -1) {
        this.formList[statusIdx].options = ['Draft', 'New']
      }

      const bodyIdx = this.formList.findIndex(f => f.key === 'body');
      if (bodyIdx !== -1) {
        this.formList[bodyIdx].type = 'TEXTAREA';
      }

      // const expireAfterDateIdx = this.formList.findIndex(item => item.key === 'expireAfterDate');
      // this.formList.splice(expireAfterDateIdx, 0, {
      //   type: 'SWITCH',
      //   label: 'Set Expires',
      //   key: 'setExpireAfterDays',
      //   tooltip: 'Set expire After Date or After Days',
      //   value: false
      // });

      let formSteps = [];

      this.formList.forEach(field => {
        if (field.type.indexOf('_OBJECT') !== -1) {
          formSteps.push(field);
        }
        if (field.required) {
          this.requiredFields.push(field.key)
        }
      })

      if (formSteps.length) {
        let objectTypes = [];
        formSteps.forEach(step => {
          objectTypes.push(step.type)
          if (step.key === 'scheduling') {
            this.requiredFields.scheduling = [];
            this.requiredFields.scheduling.push('scheduleType')
          }
        });
        this.formList = this.formList.filter(formItem => {
          return !objectTypes.includes(formItem.type)
        });

        this.translatableFields = notificationFields.baseFields.translatableFields;
        if (!this.translatableFields.length) {
          const idx = formSteps.findIndex(step => step.key === 'translations')
          if (idx !== -1) {
            formSteps.splice(idx, 1)
          }
        }

        this.steps.push(this.firstStep);
        let stepNumber = 1

        formSteps.forEach(step => {
          this.steps.push({
            title: step.label,
            subTitle: stepSubTitles[step.key],
            key: step.key,
            step: stepNumber,
          });
          this.stepKeys.push(step.key);

          stepNumber++;
        })

        this.lastStep.step = stepNumber;
        this.steps.push(this.lastStep);
        this.totalStep = formSteps.length + 1;
      }
    },
    updateCurrentStep(val) {
      this.currentStep = val;
    },
    nextStep() {
      let invalidFields = this.getInvalidFields(true);
      if (!invalidFields.length) {
        this.currentStep += 1;
      } else {
        this.setInvalidFields(invalidFields);
      }
    },
    getInvalidFields() {
      let result = [];
      //TODO: remove after implementing constraint
      this.settingsData.constraints = [];

      for (let key in this.settingsData) {
        if (this.requiredFields.includes(key) && (this.settingsData[key] === null || this.settingsData[key] === '')) {
          result.push(key);
        }
      }

      if (this.currentStep === 0) {
        if (!this.settingsData.expireAfterDays) {
          result.push('expireAfterDays');
        }
      }

      if (this.getStepNumber('scheduling') === this.currentStep) {
        for (let key in this.schedulingData) {
          if (this.requiredFields.scheduling.includes(key) && (this.schedulingData[key] === null || this.schedulingData[key] === '')) {
            result.push(key);
          }
        }
      }

      return result;
    },
    getStepNumber(key) {
      let entityObject = this.steps.find(step => step.key === key);
      if (entityObject !== undefined && entityObject.hasOwnProperty('step')) {
        return entityObject.step;
      } else {
        return -1;
      }
    },
    updateSettingsData(val) {
      if (val.expireAfterDays) {
        const expireAfterDaysElement = document.getElementsByName('expireAfterDays')[0];
        expireAfterDaysElement.parentElement.classList.remove('zq-invalid')
      }

      this.settingsData = val;
    },
    displayExpireAfterDays(val) {
      const expireAfterDateElement = document.getElementsByName('expireAfterDate')[0];
      const expireAfterDaysElement = document.getElementsByName('expireAfterDays')[0];

      if (val) {
        expireAfterDateElement.closest('.zq--form-row').style.display = 'none';
        expireAfterDaysElement.closest('.zq--form-row').style.display = '';
      } else {
        expireAfterDateElement.closest('.zq--form-row').style.display = '';
        expireAfterDaysElement.closest('.zq--form-row').style.display = 'none';
      }
    },
    updateSchedulingData(val) {
      this.schedulingData = val;
    },
    resetSchedulingValidate() {
      this.schedulingDataValid_startDate = null;
      this.schedulingDataValid_every = null;
      this.schedulingDataValid_scheduleOccurrencesLimit = null;
      this.schedulingDataValid_scheduleType = null;
    },
    updateTranslationsData(obj) {
      this.translationsMap = obj.map;
      this.translationsData = obj.val;
    },
    setInvalidFields(invalidFields) {
      invalidFields.forEach(invalidField => {
        let invalidFieldElement = document.getElementsByName(invalidField)[0];
        let invalidElement = invalidFieldElement;
        if (!invalidFieldElement.classList.contains('zq--form-row')) {
          invalidElement = invalidFieldElement.parentNode;
        }
        invalidElement.classList.add('zq-invalid');
      })
    },
    createSingleStep() {
      let invalidFields = this.getInvalidFields();
      if (!invalidFields.length) {
        this.createEntity();
      } else {
        this.setInvalidFields(invalidFields)
      }
    },
    createEntity() {
      let formData = {};

      if (Object.keys(this.settingsData).length) {
        delete this.settingsData.setExpireAfterDays;
        delete this.settingsData.constraints;
        formData = {...this.settingsData};
      }
      if (Object.keys(this.translationsData).length) {
        formData.translations = translationsTransform(this.translationsData, this.translationsMap);
      }
      if (Object.keys(this.schedulingData).length && this.schedulingData.scheduleType) {
        formData.scheduling = this.schedulingData;
        if (this.schedulingData.onlyAggregateOnActiveDays) {
          formData.scheduling.constraints.push('onlyAggregateOnActiveDays')
        }
        delete formData.scheduling.onlyAggregateOnActiveDays
      }
      if (formData.key && typeof formData.key === 'object') {
        formData.name = formData.key.name;
        formData.key = formData.key.key;
      }

      const body = [];
      body.push(JSON.parse(JSON.stringify(formData)));

      this.isReady = false;

      this.handleCreateNotifications({createNotificationRequestArray: body})
        .then(data => {
          if (data.length) {
            delay(() => {
              this.$router.push({
                name: 'PreviewNotification',
                params: {
                  id: data[0].id,
                }
              })
            }, 2000)
          } else {
            this.isReady = true;
            console.log('Something went wrong');
          }
        });
    },
  },
};
</script>

<style lang="scss">
.create-page {
  &__header {
    background-color: var(--zq-main-bg);
  }
  .zq-invalid {
    .form-control {
      border: solid 1px var(--zq-warn);
    }
  }
}
</style>
